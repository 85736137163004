import MagnumLogo from '../assets/img/logo/magnum-3x.png';
import Damacai from '../assets/img/logo/damacai-3x.png';
import TotoLogoBorder from '../assets/img/logo/toto-bordered-3x.png';
import SgLottoLogo from '../assets/img/logo/sg-lotto-3x.png';
import EightEightLogo from '../assets/img/logo/88lotto-3x.png';
import StcLogo from '../assets/img/logo/stc-3x.png';
import DaCashWanLogo from '../assets/img/logo/da-cash-wan-3x.png';
import TotoLogo from '../assets/img/logo/toto.png';
import SuccessBetPng from '../assets/img/theme/success-icon.png';
import DateTimeiconSvg from '../assets/img/theme/date-time.svg';

import MagnumLogoSquare from '../assets/img/logo/magnum-square.png';
import TotoLogoSquare from '../assets/img/logo/toto-square.png';
import TotoInversedLogo from '../assets/img/logo/toto-inversed-square.png';
import DamacaiSquare from '../assets/img/logo/damacai-square.png';
import DamacaiInversedLogo from '../assets/img/logo/damacai-inversed.png';
import SgLottoLogoSquare from '../assets/img/logo/sg-lotto-square.png';
import EightEightLogoSquare from '../assets/img/logo/88-lotto-square.png';
import StcLogoSquare from '../assets/img/logo/stc-square.png';
import GdLottoLogo from '../assets/img/logo/gdlotto.png';
import NineLottoLogo from '../assets/img/logo/9lotto.png';

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
    static _API_LOGIN = "/Account/Login";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_REGISTER = "Account/Register";
    static _API_GET_NUMBER_MEANING = "/Tool/GetNumberMeaning";
    static _API_GET_LATEST_RESULT = "Tool/GetLatestResult";
    static _API_GET_CREDIT = "/Account/GetMyCredit";
    static _API_GET_BETDATE = "/Tool/GetBetDates";
    static _API_MAKE_ORDER = "/Order/MakeOrder";
    static _API_MAKE_ORDER_ADVANCED = "/Order/MakeOrderAdvanced";
    static _API_GET_TICKET_LIST = "/Order/GetTicketList";
    static _API_GET_TICKET_DETAILS = "/Order/GetTicketDetails";
    static _API_GET_STRIKE_REPORT = "/Order/GetStrikeReport";
    static _API_GET_JACKPOT_HISTORY = "/Order/GetJackpotHistory";
    static _API_CANCEL_ORDER = "/Order/CancelOrder";
    static _API_GET_PACKAGE_LIST = "/Account/GetPackageList";
    static _API_GET_DEFAULT_DATE = "/Tool/GetAdvancedBetDefaultText";
    static _API_GET_WALLET_STATEMENT = "/Account/GetWalletStatement";
    static _API_GET_BET_REPORT = "/Tool/GetBetReport";
    static _API_GET_EAT_SEQUENCES = "/Tool/GetEatSequences";
    static _API_GET_EAT_SEQUENCE = "/Tool/GetUserEatSequence";
    static _API_UPDATE_EAT_SEQUENCE = "/Tool/UpdateUserEatSequence";
    static _API_GET_POOLS_SELECTION = "/Tool/GetPoolsSelection";
    static _API_GET_POOL_SCHEDULE = "/Tool/GetPoolSchedule";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _HOME = "/";
    static _PLAYGAME = "/home/play-game";
    static _BETTING_HISTORY = "/betting-history";
    static _BETTING_HISTORY_DETAILS = "/betting-history/details";
    static _BETTING_PAGE = "/betting";
    static _BETTING_SUMMARY = "/betting/summary";
    static _BETTING_ADVANCE = "/advance-betting";
    static _NUMBER_MEANING = "/number-meaning";
    static _RESULT_VIEW = "/result";
    static _STRIKE_HISTORY = "/strike-history";
    static _JACKPOT_HISTORY = "/jackpot-history";
    static _BET_SUCCESSFUL = "/bet-success";
    static _CANCEL_SUCCESSFUL = "/cancel-success";
    static _PACKAGES_LIST = "/packages-list";
    static _WALLET_STATEMENT = "/wallet-statement";
    static _BET_REPORT = "/bet-report";
    static _HELP = "/help";
    static _BETTING_PC_WRAPPER = "/pc-bet-wrapper"
}

export class SessionKey {
    static _LANGUAGE = "language";
}

export class Language {
    static _ENGLISH_GB = "en-GB";
    static _CHINESE_CN = "zh-CN";
    static _MALAYSIA_MALAY = "ms-MY";
    static _BN_BD = "bn-BD";
}

export class LottoBrand {
    static _MAGNUM = 0;
    static _DMC = 1;
    static _TOTO = 2;
    static _SG_LOTTO = 3;
    static _88_LOTTO = 4;
    static _STC_LOTTO = 5;
    static _DA_CASH_WAN = 6;
    static _GD = 7;
}

export class Images {
    static _MAGNUM_LOGO = MagnumLogo;
    static _DAMACAI_LOGO = Damacai;
    static _TOTO_LOGO_BORDERED = TotoLogoBorder;
    static _SG_LOTTO_LOGO = SgLottoLogo;
    static _88_LOTTO_LOGO = EightEightLogo;
    static _STC_LOTTO_LOGO = StcLogo;
    static _DA_CASH_WAN_LOGO = DaCashWanLogo;
    static _GD_LOTTO_LOGO = GdLottoLogo;

    static _TOTO_LOGO = TotoLogo;
    static _CLOCK_ICON = DateTimeiconSvg;
    static _SUCCESS_BET_ICON = SuccessBetPng;

    static _MAGNUM_LOGO_SQUARE = MagnumLogoSquare;
    static _TOTO_LOGO_SQUARE = TotoLogoSquare;
    static _TOTO_INVERSED_LOGO = TotoInversedLogo;
    static _SG_LOTTO_SQUARE = SgLottoLogoSquare;
    static _DAMACAI_LOGO_SQUARE = DamacaiSquare;
    static _DAMACAI_INVERSED_LOGO = DamacaiInversedLogo;
    static _88_LOTTO_LOGO_SQUARE = EightEightLogoSquare;
    static _STC_LOTTO_LOGO_SQUARE = StcLogoSquare;
    static _9_LOTTO_LOGO = NineLottoLogo;
}

export class POOL_CODE {
    static _TOTO = "tt";
    static _MAGNUM = "mg";
    static _DAMACAI = "kd";
    static _SG = "sg";
    static _88 = "sb";
    static _STC = "sn";
    static _CASH_SWEEP = "sr";
    static _GD = "gd";
    static _9LOTTO = "9l";
}

export class POOL_SHORT_CODE {
    static _TOTO = "T";
    static _MAGNUM = "M";
    static _DAMACAI = "K";
    static _SG = "S";
    static _88 = "B";
    static _STC = "D";
    static _CASH_SWEEP = "W";
    static _GD = "G";
    static _9LOTTO = "E";
}

export class APP_TYPE {
    static _NORMAL = "normal";
    static _GD = "gd";
    static _9LOTTO = "9lotto";
}

export const POOLS = [
    { code: "tt", name: "SportsToto 4D 多多" },
    { code: "mg", name: "Magnum 4D 萬能" },
    { code: "kd", name: "Da Ma Cai 1+3D 大馬彩" },
    { code: "sg", name: "Singapore 4D" },
    { code: "sb", name: "Lotto 88 4D" },
    { code: "sn", name: "STC 4D" },
    { code: "sr", name: "CashSweep 4D" },
    { code: "gd", name: "Grand Dragon" },
    { code: "9l", name: "9 Lotto" }
];

export const BETTING_POOLS = [
    { poolId: 1, shortCode: POOL_SHORT_CODE._MAGNUM, imgSrc: 'magnum-square.png', appType: APP_TYPE._NORMAL },
    { poolId: 2, shortCode: POOL_SHORT_CODE._DAMACAI, imgSrc: 'damacai-inversed.png', appType: APP_TYPE._NORMAL },
    { poolId: 3, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: 'toto-inversed-square.png', appType: APP_TYPE._NORMAL },
    { poolId: 4, shortCode: POOL_SHORT_CODE._SG, imgSrc: 'sg-lotto-square.png', appType: APP_TYPE._NORMAL },
    { poolId: 5, shortCode: POOL_SHORT_CODE._88, imgSrc: '88-lotto-square.png', appType: APP_TYPE._NORMAL },
    //{ poolId: 6, shortCode: POOL_SHORT_CODE._STC, imgSrc: 'stc-square.png', appType: APP_TYPE._NORMAL },
    { poolId: 7, shortCode: POOL_SHORT_CODE._CASH_SWEEP, imgSrc: 'cashsweep.png', appType: APP_TYPE._NORMAL },
    { poolId: 8, shortCode: POOL_SHORT_CODE._GD, imgSrc: 'gdlotto.png', appType: APP_TYPE._GD },
    { poolId: 9, shortCode: POOL_SHORT_CODE._9LOTTO, imgSrc: '9lotto.png', appType: APP_TYPE._9LOTTO },
];

export const BET_TYPES = [
    { label: "3D/4D", name: "3.4D", types: ["B", "S", "3A", "3C", "4A"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] },
    { label: "2D", name: "2D", types: ["2A", "2SB", "2SC", "2SD", "2SE", "2C"], appType: [APP_TYPE._NORMAL] },
    { label: "3D", name: "3D", types: ["3A", "3SB", "3SC", "3SD", "3SE", "3C"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] },
    { label: "4A/SB", name: "4D", types: ["4A", "4SB", "4SC", "4SD", "4SE", "4C"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] },
    { label: "5D", name: "5D", types: ["5D"], appType: [APP_TYPE._NORMAL] },
    { label: "6D", name: "6D", types: ["6D"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] }
];

export const PACKAGE_BET_TYPES = [
    { name: "4D", types: ["BIG", "SMALL", "4A", "4SB", "4SC", "4SD", "4SE", "4C"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] },
    { name: "3D", types: ["3A", "3SB", "3SC", "3SD", "3SE", "3C"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] },
    { name: "2D", types: ["2A", "2SB", "2SC", "2SD", "2SE", "2C"], appType: [APP_TYPE._NORMAL] },
    { name: "5D", types: ["5D"], appType: [APP_TYPE._NORMAL] },
    { name: "6D", types: ["6D"], appType: [APP_TYPE._NORMAL, APP_TYPE._GD] }
];

export const PRIZE_TYPES = ["1st Prize", "2nd Prize", "3rd Prize", "4th Prize", "5th Prize", "Starter", "Consolation"];

export class NumberMaxField {
    static _2D = 2;
    static _3D = 3;
    static _4D = 4;
    static _5D = 5;
    static _6D = 6;
}

export const SHARE_PLATFORM = {
    WHATSAPP_SHARE_URL: "https://api.whatsapp.com/send?text=",
    TELEGRAM_SHARE_URL: "tg://msg?text=",
    WECHAT_SHARE_URL: ""
}

export const BET_STATUS = {
    COMPLETE: "COMPLETE",
    LM: "LIMIT",
    SO: "SOLD_OUT"
}

export const StatusCode = {
    UNAUTHORISED: 401
}

export class NUMBER_TYPE {
    static NORMAL = { translationKey: "NORMAL", value: "NORMAL" };
    static BOX = { translationKey: "BOX", value: "BOX" };
    static IBOX = { translationKey: "IBOX", value: "IBOX" };
    static PH = { translationKey: "PH", value: "P-H" };
    static LH = { translationKey: "LH", value: "L-H" };
    static PB = { translationKey: "PB", value: "P-B" };
    static LB = { translationKey: "LB", value: "L-B" };
    static R = { translationKey: "REVERSE", value: "R" };
}

export class ENABLE_STATUS {
    static ENABLED = 1;
    static DISABLED = 0;
}

/// <summary>
/// Author: -
/// </summary>
export class EatType {
    static _2D = 'd2';
    static _3D = 'd3';
    static _4D = 'd4';
    static _5D = 'd5';
    static _6D = 'd6';
}

/// <summary>
/// Author: -
/// </summary> 
export class OrderMethod {
    static _MULTIPY = "*";
    static _DIVIDE = "/";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

/// <summary>
/// Author: -
/// Prize category Id mapped to db
/// </summary>
export class PrizeCategory {
    static _FIRST = 1;
    static _SECOND = 2;
    static _THIRD = 3;
    static _FOURTH = 4;
    static _FIFTH = 5;
    static _SIXTH = 6;
    static _STARTER = 7;
    static _CONSOLATION = 8;
}

/// <summary>
/// Author: -
/// </summary>
export class BetType {
    static _NORMAL = 1;
    static _BOX = 2;
    static _IBOX = 3;
    static _PH = 4;
    static _PB = 5;
    static _R = 6;
}

/// <summary>
/// Author: -
/// </summary>
export const _SELECT_DEBOUNCE_MS = 50;